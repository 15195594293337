<template>
  <!-- 角色管理 -->
  <div class="container" style="margin-top: 10px;">
      <div class="dataOper">
        <div class="OperInput">
          <div class="head">
          <div class="search_box">
            <div class="search_mains">
              <div class="status1">
                <router-link to="/carousel/add">
                  <div style="width:110px;margin-right:10px" class="addBtn">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
                  </div>
                </router-link>
                <div class="deleteBtnAll" style="width:110px;margin-right:20px" @click="deleteAll" id="deleteAll">
                  <i class="el-icon-delete" style="margin-right: 5px"></i>批量删除
                </div>
              </div>
            </div>
            <div class="search_mains">
              <div class="status2">
                <div style="margin-right:10px">状态</div>
                <div>
                  <el-select style="width:260px;height:36px" v-model="queryinfo.exhibitStatus" placeholder="请选择状态">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_mains">
              <div class="status2">
                <div style="margin-right:10px;margin-left:20px">搜索</div>
                <div>
                  <el-input style="width:260px;height:36px;margin-right: 20px;" placeholder="请输入标题"
                    v-model="queryinfo.title" clearable>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_mains">
              <div style="display: flex;">
                <div class="demandBtn" @click="search">
                  <img id="images" src="../../assets/images/search.png" alt="" />
                  查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table ref="multipleTable" style="font-family: PingFangRoutine" :data="tableData" tooltip-effect="dark" :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange" :cell-style="{ color: '#2C2C2C', fontSize: '14px'}">
          <el-table-column type="selection" width="55" label="全选">
          </el-table-column>
          <el-table-column prop="height" label="权重"> </el-table-column>
          <el-table-column prop="exhibitStatusStr" label="状态">
          </el-table-column>
          <el-table-column label="标题" prop="title" width="220"> </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <div id="picture">
                <img :src="scope.row.imgUrl" alt="" />
              </div>
            </template>
          </el-table-column>;
          <el-table-column label="开始时间" prop="startTime" width="220">
          </el-table-column>
          <el-table-column label="结束时间" prop="endTime" width="220">
          </el-table-column>
          <el-table-column prop="enabled" label="是否显示">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enabled" active-color="rgb(64,158,255)" inactive-color="rgb(138,138,138)"
                @change="changeComposeStatus(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template v-slot="scope">
              <div class="operating">
                <div class="edit" @click="roleEdit(scope.row.id)">
                  <img style="width: 18px;height: 18px;" src="../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="roleDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>

<script>
  import {
    getCarouseList,
    carouselDelete,
    carouselbatchDelete,
    carouselEnabled,
  } from "@/api/carousel";
  import "../../font-style/font.css";
  export default {
    name: "Carousel",
    data() {
      return {
        queryinfo: {
          endTime: "", //结束时间
          startTime: "", // 开始时间
          imgUrl: "", //图片地址
          title: "", // 标题
          exhibitStatus: "", //轮播图状态
          currPage: "",
        },
        options: [{
            name: "未上线",
            id: 0
          },
          {
            name: "未开始",
            id: 1
          },
          {
            name: "进行中",
            id: 2
          },
          {
            name: "已结束",
            id: 3
          },
        ],
        tableData: [],
        total: null,
        conceal: true,
        arr: []
      };
    },
    activated() {
    this.list();
  },
    methods: {
      //获取轮播图列表
      list() {
        getCarouseList(this.queryinfo).then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total * 1;
          this.queryinfo.currPage = ''
        });
      },
      // 分页
      handleCurrentChange(val) {
        this.queryinfo.currPage = val;
        this.list();
      },
      // table表头样式
      tableHeaderColor({
        rowIndex
      }) {
        if (rowIndex === 0) {
          return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
        }
      },
      // 复选框选择触发
      handleSelectionChange(val) {
        this.arr = val.map((item) => {
          return item.id;
        });
      },
      //搜索
      search() {
        this.total = 0
        this.queryinfo.currPage=""
        this.list();
      },
      // 重置
      reset() {
        this.total = 0
        this.queryinfo.currPage=""
        this.queryinfo.endTime = "";
        this.queryinfo.startTime = "";
        this.queryinfo.imgUrl = "";
        this.queryinfo.title = "";
        this.queryinfo.exhibitStatus = "";
        this.list();
      },
      //删除
      async roleDel(id) {
        if (!(await this._box())) return;
        carouselDelete({
          id
        }).then(() => {
          let totalPage = Math.ceil((this.total - 1) / 10);
          let currentPage =
            this.queryinfo.currPage > totalPage ?
            totalPage :
            this.queryinfo.currPage;
          this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
          this.list();
        });
      },
      //批量删除
      async deleteAll() {
        if (this.arr.length <= 0) {
          this.$confirm('请选择要操作的数据', '提示', {
            type: 'info',
          })
        }
        if (this.arr.length > 0) {
          if (!(await this._box())) return;
          carouselbatchDelete({
            ids: this.arr.join(",")
          }).then(() => {
            this.list();
          });
        }
      },
      //显示隐藏开关
      changeComposeStatus(val) {
        carouselEnabled({
          id: val.id
        }).then(() => {});
      },
      //编辑
      roleEdit(id) {
        this.$router.push({
          path: "/carousel/edit",
          query: {
            roleid: id
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  @import "../../assets/css/common.less";
  .head{
    padding: 0px;
    padding-left: 20px;
    padding-top: 17px;
    padding-bottom: 19px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }
  .specialColor {
    width: 60px;
    height: 30px;
    background: #bbbaba;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #picture {
    width: 130px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  .OperInputs {
    display: flex;
    #deleteAll {
      width: 120px;
      height: 36px;
    }
  }
  .search_mains {
    display: inline-block;
  }
  /deep/.el-input__inner {
    height: 36px;
  }
  .Inquire {
    width: 60px;
    height: 36px;
    background: #e789fb;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fafafa;
    margin-left: 20px;
    cursor: pointer;
  }
  .Inquire>img {
    height: 14px;
    margin-right: 5px;
  }
  .reset {
    width: 60px;
    height: 36px;
    background: #fe8496;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fafafa;
    margin-left: 10px;
    cursor: pointer;
  }
  .reset:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .reset>img {
    height: 14px;
    margin-right: 5px;
  }
  .status1 {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #2c2c2c;
  }
  .status2 {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFang;
    font-weight: 400;
    color: #2c2c2c;
  }
  /deep/ .el-input__icon {
      height: 100%;
      width: 25px;
      text-align: center;
      transition: all .3s;
      line-height: 36px;
  }
</style>
