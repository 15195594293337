import request from "@/utils/request";
import carouselApi from '@/const/apiUrl/carousel';
import method from "@/const/request/requestMethod";
import requestType from "@/const/request/requestType";
import contentType from "@/const/request/requestHeader";

// 获取列表
export function getCarouseList(params) {
    return request({
        url: carouselApi.carouselList,
        method: method.GET,
        data: params
    })
}
// 删除
export function carouselDelete(params) {
    return request({
        url: carouselApi.carouselDelete,
        method: method.GET,
        data: params,
        requestType: requestType.DELETE
    })
}

//新增
export function carouselAdd(params) {
    return request({
        url: carouselApi.carouselAdd,
        method: method.POST,
        data: params,
        requestType: requestType.ADD,

    })
}

//编辑
export function carouselEdit(params) {
    return request({
        url: carouselApi.carouselEdit,
        method: method.POST,
        data: params,
        requestType: requestType.EDIT,
    })
}

// 批量删除
export function carouselbatchDelete(params) {
    return request({
        url: carouselApi.carouselbatchDelete,
        method: method.GET,
        data: params,
        requestType: requestType.DELETE
    })
}

// 根据id获取
export function carouselIdWord(params) {
    return request({
        url: carouselApi.carouselIdWord,
        method: method.GET,
        data: params
    })
}

// 开关
export function carouselEnabled(params) {
    return request({
        url: carouselApi.carouselEnabled,
        method: method.GET,
        data: params,
        requestType: requestType.EDIT,

    })
}
