const carouselApi = {
    //获取轮播图列表
    carouselList: '/carousel/list',
    //删除轮播图
    carouselDelete: '/carousel/delete',
    //新增轮播图
    carouselAdd: '/carousel/add',
    //编辑轮播图
    carouselEdit: '/carousel/edit',
    //批量删除轮播图
    carouselbatchDelete:'/carousel/batchDelete',
    //根据id获取轮播图
    carouselIdWord:'/carousel/getById',
    // 开关轮播图
    carouselEnabled:'/carousel/enabled'
}


export default carouselApi